import Loading from '@/components/Loading/index.vue'
import Vue from 'vue'

const components = [
  Loading
]

components.forEach(item => {
  Vue.component(item.name, item)
})
