<template>
  <div id="app">
    <Loading v-show="LOADING"></Loading>
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  computed: {
		...mapState(['LOADING'])
	},
}
</script>

<style lang="scss">
#app {
  width: 10rem;
  margin: 0 auto;
}
</style>
