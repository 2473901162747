import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },

  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "home" */ '@/views/login/index.vue')
  },

  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/home/index.vue')
  },
  {
    path: '/product-advance',
    name: 'ProductAdvance',
    component: () => import(/* webpackChunkName: "enroll" */ '@/views/product-advance/index.vue'),
    meta: {
      title: '租赁说明'
    }
  },
  {
    path: '/financial',
    name: 'Financial',
    component: () => import(/* webpackChunkName: "enroll" */ '@/views/financial/index.vue'),
    meta: {
      title: '更多推荐'
    }
  },
  {
    path: '/join-user',
    name: 'JoinUser',
    component: () => import(/* webpackChunkName: "enroll" */ '@/views/join-user/index.vue'),
    meta: {
      title: '粉丝专享'
    }
  },
  {
    path: '/protocol/:id',
    name: 'Protocol',
    component: () => import(/* webpackChunkName: "enroll" */ '@/views/protocol/index.vue'),
    meta: {
      title: '协议'
    }
  },
  {
    path: '/traffic',
    name: 'Traffic',
    component: () => import(/* webpackChunkName: "enroll" */ '@/views/traffic/index.vue'),
    meta: {
      title: 'OK租机'
    }
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to,from,next) =>{
  if(to.meta.title){
    document.title = to.meta.title
  }
  next();
})

export default router
